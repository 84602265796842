import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

const ImagesCarousel = (props) => {
  return(
    <CarouselProvider
      naturalSlideWidth={props.width}
      naturalSlideHeight={props.height}
      totalSlides={props.slides.length}
      isPlaying={true}
      interval={4000}
    >
      <Slider>
        {
          props.slides.map((slide, index) => (
            <Slide index={index} key={index}>
              <img
                src={slide.src}
                alt={slide.hoverText}
                title={slide.hoverText}
              />
            </Slide>
          ))
        }
      </Slider>
    </CarouselProvider>
  );
}

export default ImagesCarousel;
