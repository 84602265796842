import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';

import './stylesheets/just-my-opinion.scss';

import ImagesCarousel from './game-landing-page/ImagesCarousel';
import Slide1 from './images/just-my-opinion-1.jpg';
import Slide2 from './images/just-my-opinion-2.jpg';
import Slide3 from './images/just-my-opinion-3.jpg';

import justMyOpinionLogo from './images/just-my-opinion-logo.png';

const GameJustMyOpinion = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return(

    <section>

      <Helmet>
        <title>Calamity Bay Games&trade; - Just My Opinion&trade;</title>
        <meta name="description" content="Just My Opinion&trade;: an absurd party game about reviewing the unreviewable. A game by Calamity Bay Games LLC&trade;." />
      </Helmet>

      <h2>Just My Opinion<sup>&trade;</sup></h2>

      <img src={justMyOpinionLogo} className="game-logo" alt="Just My Opinion&trade;: an absurd party game about reviewing the unreviewable" title="Just My Opinion&trade;: an absurd party game about reviewing the unreviewable" />

      <ImagesCarousel
        width="615"
        height="500"
        slides={
          [
            {
              src: Slide1,
              hoverText: "Just My Opinion™: an absurd party game about reviewing the unreviewable."
            },
            {
              src: Slide2,
              hoverText: "Just My Opinion™: an absurd party game about reviewing the unreviewable."
            },
            {
              src: Slide3,
              hoverText: "Just My Opinion™: an absurd party game about reviewing the unreviewable."
            }
          ]
        }
      />

      <p>Have you ever wanted to post a crappy review to be funny but felt too bad about it? Have you ever wanted to review abstract concepts or natural splendors? Do you work in retail and want to cathartically role-play as a nightmare customer? Just My Opinion is the game for you!</p>

      <h2 className="cta"><a href="https://www.thegamecrafter.com/games/just-my-opinion" target="_blank" rel="noopener noreferrer">Buy Now</a></h2>


    </section>
  );
}

export default GameJustMyOpinion;
