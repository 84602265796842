const RepeatingFlourishDivider = (props) => {
  
  const dividerStyle = {
    height: `${props.height}px`,
    backgroundImage: `url(${props.image})`,
    backgroundSize: `auto ${props.height}px`
  };

  return(
    <section className="repeating-flourish-divider"
      style={ dividerStyle }
    ></section>
  );
}

export default RepeatingFlourishDivider;
