{/* Known issue with `muted` value not being rendered in the DOM: https://github.com/facebook/react/issues/10389 */}

const VideoCenter = (props) => {
  return(
    <section>
      <video
        autoPlay={ props.autoPlay }
        controls
        loop={ props.loop }
        muted={ props.muted }
        poster={ props.thumbnailSrc }
        src={ props.videoSrc }
        type={ props.videoType }
      ></video>
    </section>
  );
}

export default VideoCenter;
