// At the moment, this is almost identical to the TextSideFlourish component. But I'm going to make it distinct so that we can be as flexible as needed - I suspect this will change a lot; I don't want to get locked in.
import sideFlourish from '../images/veil-of-dust/text-side-flourish.png';

const TextWithHeader = (props) => {

  return(
    <div className="subheader-with-side-flourishes wishlist">
      <img src={sideFlourish} className="left" />
      <h1><a class="purchase" href="https://store.steampowered.com/app/1593170/Veil_of_Dust_A_Homesteading_Game" target="_blank">Buy it on Steam!</a></h1>
      <img src={sideFlourish} className="right" />
    </div>
  );
}

export default TextWithHeader;
