const SubNav = (props) => {
  
  const scrollToSection = (section) => {
    if(document.getElementById(section)){
      document.getElementById(section).scrollIntoView({behavior: "smooth"});
    }
  }

  const handleKeyPress = (event, section) => {
    if (event.key == 'Enter') {
      scrollToSection(section);
    }
  }

  return(
    <section className="subnav">
      {
        props.linksArray.map((link, index) => (
          <div key={ index }>
            <span
              className="pseudo-link"
              onKeyPress={(event) => handleKeyPress(event, link.id)}
              onClick={() => scrollToSection(link.id)}
              tabIndex="0"
            >
              { link.text }
            </span>
            <span className="divider">{ props.linksArray.length != index + 1 ? '|' : '' }</span>
          </div>
        ))
      }
    </section>
  );
}

export default SubNav;
