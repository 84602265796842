import { Component } from 'react';

import {
  Switch,
  Link
} from 'react-router-dom';

import { Route } from 'react-router';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';

// Font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons' // brand icons
import { faBars } from '@fortawesome/free-solid-svg-icons' // icons in the general library
library.add(fab, faBars)

import { FormspreeProvider } from '@formspree/react';

import logo from './images/logo-512.png';
import './stylesheets/styles.scss';

import Blog from './Blog';
import Contact from './Contact';
import DataBlogPosts from './data-blog-posts.json';
import Games from './Games';
import GameJustMyOpinion from './GameJustMyOpinion';
import GameVeilOfDust from './GameVeilOfDust';
import Press from './Press';
import PageNotFound from './PageNotFound';

import InputForm from './InputForm';

ReactGA.initialize('UA-166270425-1');

export default class App extends Component {
  constructor(){
    super();
    this._setStateOnInitialLoad();
  }

  _setStateOnInitialLoad(){
    const relativePath = window.location.pathname;
    let thisPage = '';
    if(relativePath == '/'){
      thisPage = 'games';
    } else if(relativePath.indexOf('/games/') > -1){
      thisPage = relativePath.split('/games/')[1];
      this._checkForUniqueStylesheet(thisPage);
    } else {
      thisPage = relativePath.split('/')[1];
    }
    this.state = {
      page: thisPage,
      menu_active: false
    }
  }

  _toggleMenu(){
    this.setState({
      menu_active: !this.state.menu_active
    });
  }

  // Updating this to use fat arrow notation. This makes it easier to pass as a prop. I'll likely convert the other sibling functions in a similar fashion later.
  handleNav = (page) => {
    this.setState({
      page: page,
      menu_active: false
    });
    this._checkForUniqueStylesheet(page);
  }

  _checkForUniqueStylesheet(page){
    // Future: this will also handle other game-page landing treatments. Very crude right now.
    document.body.classList.remove('veil-of-dust');
    document.body.classList.remove('just-my-opinion');
    if(page == 'veil-of-dust'){
      document.body.classList.add(page);
    } else if(page == 'just-my-opinion'){
      document.body.classList.add('just-my-opinion');
    }
  }

  render() {
    return (
      <FormspreeProvider project="1624508392254995875">
        <div className="App">

          <Helmet>
            <title>Calamity Bay Games&trade;</title>
            <meta name="description" content="Calamity Bay Games&trade; is a hobby studio composed of husband-and-wife team Elle Kelsheimer and Mike Caputo. We are the creators of the board game Grimmsdorf&reg;, and the upcoming computer game Veil of Dust&trade;: a Homesteading Game." />
          </Helmet>

          <div className="container">
            <header>
              <Link to={`/games`} onClick={() => this.handleNav('games')}>
                <img src={logo} className="main-logo" alt="Calamity Bay Games™" title="Calamity Bay Games™" />
              </Link>
            </header>
            <nav>
              <div className={this.state.menu_active ? 'links-wrapper menu-open' : 'links-wrapper'}>
                <span className="menu-button" onClick={() => this._toggleMenu()}><FontAwesomeIcon icon={faBars} title="Toggle menu" /> Menu</span>
                <Link to={`/games`} onClick={() => this.handleNav('games')} className={this.state.page === 'games' ? 'active' : ''}>All Games</Link>
                <Link to={`/blog`} onClick={() => this.handleNav('blog')} className={this.state.page === 'blog' ? 'active' : ''}>Blog</Link>
                <Link to={`/contact`} onClick={() => this.handleNav('contact')} className={this.state.page === 'contact' ? 'active' : ''}>Contact</Link>
              </div>
            </nav>

            <main>
              <Switch>
                <Route exact path='/blog'><Blog /></Route>
                {
                  DataBlogPosts.map(function(blogPost, index){
                    return(
                      <Route key={blogPost.route} exact path={'/blog/'+blogPost.route}>
                        <Blog blogPost={blogPost.route} />
                      </Route>
                    );
                  })
                }
                <Route exact path='/contact'><Contact /></Route>
                <Route exact path='/'><Games handleNav={this.handleNav} /></Route>
                <Route exact path='/games'><Games handleNav={this.handleNav} /></Route>
                <Route exact path='/games/just-my-opinion'><GameJustMyOpinion /></Route>
                <Route exact path='/games/veil-of-dust'><GameVeilOfDust handleNav={this.handleNav} /></Route>
                <Route exact path='/press'><Press handleNav={this.handleNav} /></Route>
                <Route path="*"><PageNotFound /></Route>
              </Switch>
            </main>
            <section className="joinDiscordCta">
              <a href="https://discord.gg/mhhzZCk9JC" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon size="lg" icon={['fab', 'discord']} title="Chat with us on Discord" />
                <span>Join our Discord community! Share tips and discuss with other players, ask us about our current projects, and come have fun with the game creators!</span>
              </a>
            </section>
            <section className="joinMailingListCta">
              <InputForm whichForm="joinMailingList" header="Join our Mailing List" subtext="Latest news, sneak peeks, and occasional exclusive offers." />
            </section>
            <footer>
              <p className="social-links">
                <a href="https://twitter.com/calamitybaygame" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon size="lg" icon={['fab', 'twitter']} title="Follow us on Twitter" />
                </a> &middot;&nbsp;
                <a href="https://store.steampowered.com/app/1593170/Veil_of_Dust_A_Homesteading_Game/" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon size="lg" icon={['fab', 'steam']} title="Veil of Dust: A Homesteading Game&trade; on Steam" />
                </a> &middot;&nbsp;
                <a href="https://discord.gg/mhhzZCk9JC" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon size="lg" icon={['fab', 'discord']} title="Chat with us on Discord" />
                </a> &middot;&nbsp;
                <a href="https://instagram.com/calamitybaygames/" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon size="lg" icon={['fab', 'instagram']} title="Follow us on Instagram" />
                </a> &middot;&nbsp;
                <a href="https://facebook.com/calamitybaygames" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon size="lg" icon={['fab', 'facebook-square']} title="Follow us on Facebook" />
                </a> &middot;&nbsp;
                <a href="https://www.youtube.com/channel/UCtmxPjBXhqoVsBSCl_djXzQ?sub_confirmation=1" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon size="lg" icon={['fab', 'youtube']} title="Subscribe to our YouTube channel" />
                </a>
              </p>
              <hr />
              <Link to={`/press`} onClick={() => this.handleNav('contact')} className={this.state.page === 'contact' ? 'active' : ''}>Press Contact</Link>
              <p>All material is &copy; Calamity Bay Games LLC&trade;</p>
            </footer>
          </div>
        </div>
      </FormspreeProvider>
    );
  }
}
