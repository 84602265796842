import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import InputForm from './InputForm';

const Press = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return(
    <section>

      <Helmet>
        <title>Calamity Bay Games&trade; - Press</title>
        <meta name="description" content="Calamity Bay Games&trade; - Press" />
      </Helmet>

      <h4>Hello press writers, streamers, and employees of the Daily Bugle looking for the next big scoop.</h4>

      <p>Looking for a press kit for Veil of Dust? <a href={process.env.PUBLIC_URL + '/media/veil-of-dust-press-kit.pdf'} target="_blank" rel="noopener noreferrer">Download it here</a>.</p>

      <InputForm whichForm="contactForm" header="Press Contact" subtext="Have a question about one of our games? Want to schedule an interview? Just want to say hi? Drop us a line and we'll get back to you!" />

    </section>
  );
}

export default Press;
