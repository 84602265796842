import aineShaneEating from '../images/veil-of-dust/aine-shane-eating.png';
import headerFlourish from '../images/veil-of-dust/header-flourish.png';
import headerText from '../images/veil-of-dust/header-text.png';
import trailerPoster from '../images/veil-of-dust/trailer-poster.png';
import newTrailer from '../videos/vod-trailer-2022.mp4';

import ArtTextLockup from './ArtTextLockup';
import VideoCenter from './VideoCenter';
import Wishlist from './Wishlist';

const TopBanner = (props) => {
  return(
    <section className="top-banner" id={props.id}>
      <div className="gradient-sky">
        <img src={headerFlourish} className="header-flourish top" />
        <img src={headerText} className="wordmark" alt="Veil of Dust: A Homesteading Game&trade;" title="Veil of Dust: A Homesteading Game&trade;" />
        <p>A historically-grounded fantasy homesteading game about finding your way after loss</p>
        <img src={headerFlourish} className="header-flourish bottom" />
        <div className="desert-horizon"></div>
      </div>

      <div className="gradient-ground">
        <VideoCenter
          autoPlay={false}
          loop={false}
          muted={false}
          thumbnailSrc={trailerPoster}
          videoSrc={newTrailer}
          videoType="video/webm"
        />
        <Wishlist handleNav={props.handleNav} />
        <ArtTextLockup
          image={aineShaneEating}
          imageAltText="Veil of Dust: A Homesteading Game™ is an environmental exploration of what comes after devastating loss."
          paragraphsArray={['Veil of Dust: A Homesteading Game™ is an environmental exploration of what comes after devastating loss.', 'Survive in a sparse landscape and find new meaning through your adventures.', 'Make peace with the past and forge a new future by building relationships, creating a new home, and discovering a world beyond the veil.']}
        />
      </div>
    </section>
  );
}

export default TopBanner;
