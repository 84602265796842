import { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import InputForm from './InputForm';

const Contact = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return(
    <section>

      <Helmet>
        <title>Calamity Bay Games&trade; - Contact</title>
        <meta name="description" content="Calamity Bay Games&trade; - Contact Us" />
      </Helmet>

      <InputForm whichForm="contactForm" header="Contact Us" subtext="Any inquiries can be submitted here." />

    </section>
  );
}

export default Contact;
