const VideoTextLockup = (props) => {

  return(
    <section className="video-text-lockup">
      <div className="copy">
        <h3>{ props.title }</h3>
        <p>{ props.subtext }</p>
        <ul>
          {
            props.bulletItemsArray.map((bulletItem, index) => (
              <li key={ index }>{ bulletItem }</li>
            ))
          }
        </ul>
      </div>
      <video
        autoPlay={ props.autoPlay }
        controls
        loop={ true }
        muted
        src={ props.videoSrc }
        type="video/mp4"
      ></video>
    </section>
  );
}

export default VideoTextLockup;
