import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const PageNotFound = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return(
    <section>

      <Helmet>
        <title>Calamity Bay Games&trade; - 404 Page Not Found</title>
        <meta name="description" content="Calamity Bay Games&trade; - Page Not Found" />
      </Helmet>

      <h2>404 Page Not Found</h2>

      <p>Oops, we're not sure how you got here! <Link to={`/games`}>Try going back to the homepage if you got lost.</Link></p>

    </section>
  );
}

export default PageNotFound;
