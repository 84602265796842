import React, { useState, useEffect } from 'react';
import { ValidationError, useForm } from '@formspree/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons' // icons in the general library
library.add(faCircleNotch)

let listenForInputReset = null; // Probably not ideal, but I'm using this flag to navigate the lifecycle of `formState`, and clear out the form inputs when the user submits the form.

const InputForm = (props) => {
  const whichForm = props.whichForm;
  const header = props.header;
  const subtext = props.subtext;
  const [formState, submitForm] = useForm(whichForm);
  
  useEffect(() => {
    if(formState.submitting){
      listenForInputReset = true;
    }
    if(formState.succeeded && listenForInputReset){
      listenForInputReset = false;
      setInputs({
        name: '',
        email: '',
        message: ''
      });
    }
  });

  const [inputs, setInputs] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleOnChange = event => {
    event.persist();
    setInputs(prev => ({
      ...prev,
      [event.target.id]: event.target.value
    }));
  };

  return(
    <section>
      <h3>{ header }</h3>
      <p>{ subtext }</p>
      <form onSubmit={submitForm}>
        <label>Name</label>
        <input type="text" name="name" id="name" onChange={handleOnChange} value={inputs.name} />

        <label>Email *</label>
        <input type="email" name="email" id="email" onChange={handleOnChange} value={inputs.email} required />
        <ValidationError field="email" prefix="Email" className="form-error" errors={formState.errors} />
        { whichForm == 'contactForm' &&
          <div>
            <label>Message *</label>
            <textarea rows="4" name="message" id="message" onChange={handleOnChange} value={inputs.message}></textarea>
            <ValidationError field="message" prefix="Message" className="form-error" errors={formState.errors} />
          </div>
        }

        <button type="submit" title="Submit" disabled={ formState.submitting ? 'disabled' : '' } >
          { formState.submitting
            ? <span>Submitting <FontAwesomeIcon icon={faCircleNotch} spin /></span>
            : <span>Submit</span>
          }
        </button>
        { formState.succeeded &&
          <p>We've received your submission. Thanks for being a part of Calamity Bay Games!</p>
        }
      </form>
    </section>
  );
}

export default InputForm;
