import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import DataBlogPosts from './data-blog-posts.json';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';

const Blog = (props) => {

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const requestedBlogPost = props.blogPost;

  if(requestedBlogPost == null){
    return(
      <section>

        <Helmet>
          <title>Calamity Bay Games&trade; - Blog</title>
          <meta name="description" content="Read our blog to keep up to date with game development and our other musings on the nature and purpose of games." />
        </Helmet>

        <h2>Development Blog</h2>
          {
            DataBlogPosts.map(function(blogPost, index){
              return(
                <div key={blogPost.route}>
                  <h3><Link to={'/blog/'+blogPost.route}>{blogPost.title}</Link></h3>
                  <p><em>{blogPost.author}, {blogPost.date}</em></p>
                  <p>{blogPost.teaser_text}</p>
                  <p><Link to={'/blog/'+blogPost.route}>Read more...</Link></p>
                </div>
              );
            })
          }
      </section>
    );
  } else {
    return(
      <section>
        {
          DataBlogPosts.filter(blogPost => blogPost.route === requestedBlogPost).map(function(blogPost, index){
            let blogText = '';
            blogPost.text.map(function(textLine, index){
              return blogText += textLine;
            });
            return(
              <section key={blogPost.title}>

                <Helmet>
                  <title>Calamity Bay Games&trade; blog: {blogPost.title}</title>
                  <meta name="description" content={`Calamity Bay Games blog: ${blogPost.title} by ${blogPost.author}`} />
                </Helmet>

                <h2>{blogPost.title}</h2>
                <p><em>{blogPost.author}, {blogPost.date}</em></p>
                <div dangerouslySetInnerHTML = {
                  { __html: blogText }
                } />
                <p><em>Like what you see here? <Link to={`/contact`}>Join our mailing list</Link> and stay up-to-date with our game-making adventures.</em></p>
                <p><Link to={`/blog`}>&larr; Back to blog posts</Link></p>
              </section>
            );
          })
        }
      </section>
    );
  }
}

export default Blog;
