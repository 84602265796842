import subheaderFlourish from '../images/veil-of-dust/subheader-flourish.png';
import sectionCornerFlourish from '../images/veil-of-dust/section-corner-flourish.png';
import Wishlist from './Wishlist';

const SectionWrapper = (props) => {

  return(
    <section className="section-wrapper" id={props.id}>

      {/* This component uses a combination of images and `dotted-border` divs to handle the complex border. */}

      <img src={sectionCornerFlourish} className="corner-flourish top left" />
      <div className="dotted-border top"></div>
      <img src={sectionCornerFlourish} className="corner-flourish top right" />
      <div className="dotted-border left"></div>

      <div className="section-content">
        <div className="section-header">
          <img src={subheaderFlourish} className="subheader-flourish left" />
          <div className="text">
            <h3>{props.headingTitle}</h3>
            <p>{props.headingBody}</p>
          </div>
          <img src={subheaderFlourish} className="subheader-flourish right" />
        </div>
        {/* Render all child components passed in to this wrapper component: */}
        <div className="section-body">
          { props.children }
        </div>
        <Wishlist handleNav={props.handleNav} />
      </div>

      <div className="dotted-border right"></div>
      <img src={sectionCornerFlourish} className="corner-flourish bottom left" />
      <div className="dotted-border bottom"></div>
      <img src={sectionCornerFlourish} className="corner-flourish bottom right" />

    </section>
  );
}

export default SectionWrapper;
