import sideFlourish from '../images/veil-of-dust/text-side-flourish.png';

const TextWithHeader = (props) => {
  return(
    <div className="subheader-with-side-flourishes">
      <img src={sideFlourish} className="left" />
      <h3>{ props.text }</h3>
      <img src={sideFlourish} className="right" />
    </div>
  );
}

export default TextWithHeader;
