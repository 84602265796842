import TextSideFlourish from './TextSideFlourish';

const TextWithHeader = (props) => {
  return(
    <section className="text-width-header">
      <TextSideFlourish text={props.header} />
      <p>{ props.text }</p>
    </section>
  );
}

export default TextWithHeader;
