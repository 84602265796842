import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';

import './stylesheets/veil-of-dust.scss';

import aineFarmingVideo from './videos/aine-farming.mp4';
import aineForagingVideo from './videos/aine-foraging.mp4';
import aineHuntingVideo from './videos/aine-hunting.mp4';
import aineCraftingVideo from './videos/aine-crafting.mp4';
import aineCookingVideo from './videos/aine-cooking.mp4';
import battleVideo from './videos/battle-scene.mp4';

import repeatableFloralSquare from './images/veil-of-dust/repeatable-floral-square.png';

import portraitAine from './images/veil-of-dust/portrait-aine.png';
import portraitShane from './images/veil-of-dust/portrait-shane.png';

import portraitAlysse from './images/veil-of-dust/portrait-alysse.png';
import portraitArthur from './images/veil-of-dust/portrait-arthur.png';
import portraitLaan from './images/veil-of-dust/portrait-laan.png';

import portraitSylvia from './images/veil-of-dust/portrait-sylvia.png';
import portraitNina from './images/veil-of-dust/portrait-nina.png';
import portraitClara from './images/veil-of-dust/portrait-clara.png';
// Shane's romances to be added here.
import npcHamlet from './images/veil-of-dust/npc-hamlet.png';
import npcLaughingCrow from './images/veil-of-dust/npc-laughing-crow.png';

import SubNav from './game-landing-page/SubNav';
import TopBanner from './game-landing-page/TopBanner';
import SectionWrapper from './game-landing-page/SectionWrapper';
import VideoTextLockup from './game-landing-page/VideoTextLockup';
import TextWithHeader from './game-landing-page/TextWithHeader';
import FlexImageLockup from './game-landing-page/FlexImageLockup';
import VideoCenter from './game-landing-page/VideoCenter';
import RepeatingFlourishDivider from './game-landing-page/RepeatingFlourishDivider';

const GameVeilOfDust = (props) => {

  useEffect(() => {
    document.getElementById('beginGameContent').scrollIntoView();  // For this treatment, we will pan down to the VoD section immediately. The navigation will be above it, but will be scrolled away so that the viewer can focus on the VoD experience.
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return(

    <section>

      <Helmet>
        <title>Calamity Bay Games&trade; - Veil of Dust: A Homesteading Game&trade;</title>
        <meta name="description" content="Veil of Dust: A Homesteading Game&trade; is a historically-grounded fantasy homesteading game about finding your way after loss" />
      </Helmet>

      <TopBanner id="beginGameContent" handleNav={props.handleNav} />
      <RepeatingFlourishDivider image={repeatableFloralSquare} height="40" />
      <SubNav
        linksArray={[
          {id: "farmForageHunt", text: "Farm, Forage, & Hunt"},
          {id: "forgeRelationships", text: "Forge Relationships"},
          {id: "fightAndPrevail", text: "Fight & Prevail"},
          {id: "cookAndCraft", text: "Cook & Craft"}
        ]}
      />
      <RepeatingFlourishDivider image={repeatableFloralSquare} height="40" />
      <SectionWrapper
        handleNav={props.handleNav}
        id="farmForageHunt"
        headingTitle="Farm, Forage, &amp; Hunt to Survive"
        headingBody="Living in the desert isn't easy, but the magical abilities that force the protagonists to isolate themselves also helps them make the most of the environment. Farm, forage, and hunt to scrape by in the sparse desert of Eastern Oregon."
      >
        <VideoTextLockup
          videoSrc={aineFarmingVideo}
          autoPlay={true}
          title="Farming"
          subtext="Manage your seeds, energy, and resources to keep a steady supply of sustenance."
          bulletItemsArray={['Maintain your supply of seeds', 'Use magic to hasten growth', 'Grow certain crops to fulfill different needs', 'Craft items such as mulch to ward off the desert heat']}
        ></VideoTextLockup>
        <div className="dotted-divider" />
        <VideoTextLockup
          videoSrc={aineForagingVideo}
          autoPlay={true}
          title="Foraging"
          subtext="The landscape holds ample resources if you know where to look"
          bulletItemsArray={['Walk the vast expanses and keep your eyes peeled', 'Grab forageables quickly before they escape your notice', 'Obtain unique resources you can\'t grow at home']}
        ></VideoTextLockup>
        <div className="dotted-divider" />
        <VideoTextLockup
          videoSrc={aineHuntingVideo}
          autoPlay={true}
          title="Hunting"
          subtext="Collect resources such as meat and hides to supplement your garden's production"
          bulletItemsArray={['Track your prey across the open landscape', 'Stalk the creature in the sage', 'Attack skillfully to avoid an escape']}
        ></VideoTextLockup>
      </SectionWrapper>
      <RepeatingFlourishDivider image={repeatableFloralSquare} height="40" />
      <SectionWrapper
        handleNav={props.handleNav}
        id="forgeRelationships"
        headingTitle="Forge Relationships"
        headingBody="Survival isn't just about finding food and shelter. It's also about making life worth living. The support and attention of loved ones will bolster your spirit and give you strength."
      >
        <TextWithHeader
          header="The Callahan siblings"
          text="Our protgonists' relationship is simultaneously a rock to both of them and a source of conflict, as chaos and circumstance tests the roles laid down by their personalities, birth order, and restrictive 1860s gender roles. Will they come to support one another with their unique talents, or will the sudden appearance of ferocious monsters push them to the breaking point?"
        />
        <FlexImageLockup
          textFirst={false}
          lockups={
            [
              {
                image: portraitAine,
                name: "",
                description: "Áine, the younger sister, is trying to be her own person and shoulder some of the burden with her remarkable abilities."
              },
              {
                image: portraitShane,
                name: "",
                description: "Shane, the older brother, is trying to be true to the directive his late father left - protect your sister from those who would find her magic frightening enough to do her harm."
              }
            ]
          }
        />
        <div className="dotted-divider" />
        <FlexImageLockup
          header="Áine's Romances"
          textFirst={false}
          lockups={
            [
              {
                image: portraitLaan,
                name: "Prince Laan",
                description: "A quiet and studious prince of the Fae. Despite their obvious differences, he seems quite taken with Áine..."
              },
              {
                image: portraitAlysse,
                name: "Alysse Bengtsson",
                description: "Reserved and nervous, this water nymph seems unusually withdrawn. There's something strange about her..."
              },
              {
                image: portraitArthur,
                name: "Arthur Everdell",
                description: "Despite attempts to blend in, this amateur botanist stands out nonetheless. Is it the Canadian accent or the genteel mannerisms?"
              }
            ]
          }
        />

        <div className="dotted-divider" />

        <FlexImageLockup
          header="Shane's Romances"
          textFirst={false}
          lockups={
            [
              {
                image: portraitSylvia,
                name: "Sylvia Wagner",
                description: "The wealthy daughter of the town's Pastor, Sylvia is an established member of the town's fabric. There is real interest between her and Shane, despite the vast gulf between their stations."
              },
              {
                image: portraitNina,
                name: "Nina Williams",
                description: "Armed with her snarky sense of humor, this shopkeep is accustomed to taking care of herself in La Grande. But she and Share both have their share of secrets to share..."
              },
              {
                image: portraitClara,
                name: "Clara Taylor",
                description: "Clever and confident, Clara recently lost her husband and must find a way to take care of herself and her son. She and Shane both may actually have a lot in common..."
              }
            ]
          }
        />
        <div className="dotted-divider" />
        <FlexImageLockup
          header="Other NPCs"
          textFirst={true}
          lockups={
            [
              {
                image: null,
                name: "Animals & Spirits",
                description: "Among her other powers, Áine has the ability to speak with animals. Surely their natural adaptedness will provide welcome guidance."
              },
              {
                image: null,
                name: "Townsfolk",
                description: "Shane is nervous about prying eyes, so while Áine stays in the wilds, he puts on a pleasant smile and tries to appear as normal as possible."
              }
            ]
          }
        />
      </SectionWrapper>
      <RepeatingFlourishDivider image={repeatableFloralSquare} height="40" />
      <SectionWrapper
        handleNav={props.handleNav}
        id="fightAndPrevail"
        headingTitle="Fight &amp; Prevail"
        headingBody="A homesteader isn't usually well-equipped for armed struggle, but desperate times call for desperate measures. Stand shoulder-to-shoulder with your sibling to defend your homestead against magical monsters."
      >
        <VideoCenter
          autoPlay={true}
          loop={true}
          muted={true}
          videoSrc={battleVideo}
          videoType="video/mp4"
        />
      </SectionWrapper>
      <RepeatingFlourishDivider image={repeatableFloralSquare} height="40" />
      <SectionWrapper
        handleNav={props.handleNav}
        id="cookAndCraft"
        headingTitle="Cook &amp; Craft"
        headingBody="Make the most of sparse resources around you with skillful craftmanship and culinary resourcefulness"
      >
        <VideoTextLockup
          videoSrc={aineCookingVideo}
          autoPlay={true}
          title="Cooking"
          subtext="Frontier cuisine is more about filling bellies than making an impression - scrape together what you can to conquer scarcity. Overcome challenges with tools and handicrafts that you create."
          bulletItemsArray={['Cook up what you can to stave off hunger', 'Resourceful use of every component will yield extra food', 'Obtain new tools to unlock recipes', 'Tasty dishes will help keep morale up; hearty dishes will restore health']}
        ></VideoTextLockup>
        <div className="dotted-divider" />
        <VideoTextLockup
          videoSrc={aineCraftingVideo}
          autoPlay={true}
          title="Crafting"
          subtext="The desert presents unique challenges. Overcome them with tools and handicrafts that you create"
          bulletItemsArray={['Outfit your homestead with upgrades', 'Ease the challenges of farming', 'Respond to challenges and pressures in creative ways']}
        ></VideoTextLockup>
      </SectionWrapper>

    </section>
  );
}

export default GameVeilOfDust;
