import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';

import { Link } from 'react-router-dom';

import veilOfDustOverview from './images/veil-of-dust-overview.jpg';
import grimmsdorfGameComponents from './images/grimmsdorf-game-components.jpg';
import justMyOpinionGameComponents from './images/just-my-opinion-2.jpg';

const Games = (props) => {

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname + window.location.search);
    // My basic understanding of the ending array, `[]` !
    // 1. leaving it out: means that the component will update on every re-render
    // 2. Setting it as an empty array will ensure that it only renders once
    // 3. Adding contents will allow you to specify _when_ to re-render the component.
  }, []);

  return(

    <section className="our-games">

      <Helmet>
        <title>Calamity Bay Games&trade; - All Games</title>
        <meta name="description" content="Learn more about the games developed by Calamity Bay Games&trade;, including Veil of Dust: A Homesteading Game&trade;, Grimmsdorf&reg;, and Just My Opinion&trade;." />
      </Helmet>

      <h2>Veil of Dust: A Homesteading Game<sup>&trade;</sup></h2>
      <Link to='/games/veil-of-dust' onClick={() => props.handleNav('veil-of-dust')} className="game-homepage-link">
        Go to Veil of Dust Homepage ▶
        <img src={veilOfDustOverview} className="game-image" alt="Veil of Dust: A Homesteading Game&trade;" title="Veil of Dust: A Homesteading Game&trade;" />
      </Link>
      <p>Veil of Dust: A Homesteading Game is a story-driven farming RPG exploring what comes after devastating loss. Survive in a sparse landscape and find new meaning through your adventures. Make peace with the past and forge a new future by building relationships, creating a new home, and discovering a world beyond the veil.</p>

      <hr />

      <h2>Grimmsdorf<sup>&reg;</sup>: <span className="subtitle">a coöperative town-building game of lore, exploration, and survival</span></h2>
      <a href="https://grimmsdorf.com" target="_blank" rel="noopener noreferrer" className="game-homepage-link">
        Go to Grimmsdorf Homepage ▶
        <img src={grimmsdorfGameComponents} className="game-image" alt="Grimmsdorf&reg;: a coöperative town-building game of lore, exploration, and survival" title="Grimmsdorf&reg;: a coöperative town-building game of lore, exploration, and survival" />
      </a>
      <p>Grimmsdorf is a 2-4 player coöperative town-building game of lore, exploration, and survival set in a dark fairytale forest sometime in Europe's distant past. In this world of struggle and ignorance, wearied villagers are never sure where reality ends and fantasy begins. Fairies, famines, wolves, and witches seem to lurk around every bend. Real history and oral mythologies are blended together in one world of mystery, fear, and adventure.</p>

      <hr />

      <h2>Just My Opinion<sup>&trade;</sup>: <span className="subtitle">an absurd party game about reviewing the unreviewable</span></h2>
      <Link to='/games/just-my-opinion' onClick={() => props.handleNav('just-my-opinion')} className="game-homepage-link">
        Go to Just My Opinion Homepage ▶
        <img src={justMyOpinionGameComponents} className="game-image" alt="Just My Opinion&trade;: an absurd party game about reviewing the unreviewable" title="Just My Opinion&trade;: an absurd party game about reviewing the unreviewable" />
      </Link>

      <hr />

      <h2>About Us</h2>
      <p>Calamity Bay Games&trade; is a hobby studio composed of {Math.floor(Math.random() * 2) == 0 ? 'wife-and-husband team Elle Kelsheimer and Mike Caputo' : 'husband-and-wife team Mike Caputo and Elle Kelsheimer'}. We make games that are based on our passions of history, sociology, system design, art, and having fun. We are located in beautiful and historic Kingston, NY. If you're interested in following our adventures, you can <Link to={`/contact`}>join our mailing list here.</Link></p>

    </section>
  );
}

export default Games;
