// This component is used for image-and-text lockups. Depending on whether or not `text-first` is passed in as `true` or `false`, the display will adjust accordingly and will flip the image and text lockup.

import TextSideFlourish from './TextSideFlourish';

const FlexImageLockup = (props) => {
  return(
    <section>
      {props.header &&
        <TextSideFlourish text={props.header} />
      }
      {/* This "subheader" portion should* be temporary. We shouldn't* need it once Shane's romances are added. */}
      {
        props.subheader != "" &&
        <p style={{ textAlign: 'center', marginTop: '-35px', marginBottom: '40px' }}>{ props.subheader }</p>
      }
      <div className="flexBox">
        {
          props.lockups.map((lockup, index) => (
            <div className={ props.textFirst ? 'text-first' : '' } key={index}>
              {
                lockup.image != null &&
                <img src={ lockup.image } alt={`${ lockup.name }: ${ lockup.description }`} title={`${ lockup.name }: ${ lockup.description }`} />
              }
              <div>
                <h3>{ lockup.name }</h3>
                <p>{ lockup.description }</p>
              </div>
            </div>
          ))
        }
      </div>
    </section>
  );
}

export default FlexImageLockup;
