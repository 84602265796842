const ArtTextLockup = (props) => {
  return(
    <section className="art-text-lockup">
      <img src={props.image} alt={props.imageAltText} title={props.imageAltText} />
      <div>
        {
            props.paragraphsArray.map((paragraph, index) => (
              <p key={ index }>{ paragraph }</p>
            ))
          }
      </div>
    </section>
  );
}

export default ArtTextLockup;
